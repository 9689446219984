.event-table {
  max-width: 1056px;
  width: 100%;
}
@media (min-width: 1200px) {
  .event-table {
    max-width: 1056px;
    width: 100%;
  }
}
.category-table {
  max-width: 600px;
  width: 100%;
}
@media (min-width: 1200px) {
  .category-table {
    max-width: 600px;
    width: 100%;
  }
}
