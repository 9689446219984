/* progress bar */
.animated-progress {
  animation: progress-bar-stripes 1s linear infinite;
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

/* range picker */
input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #dee2e6;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4361ee;
  margin-top: -4px;
}

.dark input[type="range"]::-webkit-slider-runnable-track {
  background: #1b2e4b;
}

.dark input[type="range"] {
  background-color: transparent;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:active::-webkit-slider-thumb {
  background: #4361eec2;
  cursor: pointer;
}

/* input group */
.input-group-dropodwn > div {
  @apply h-full;
}

/* noUI slider */
.noUi-connect {
  background-color: #4361ee !important;
}
.noUi-target {
  @apply !h-2 !shadow-none dark:border-[#17263c] dark:bg-[#121e32] dark:text-white-dark;
}
.noUi-horizontal .noUi-handle {
  @apply !h-5 !w-6 cursor-pointer border border-[#D3D3D3] !shadow-[inset_0_0_1px_#fff,inset_0_1px_7px_#ebebeb,0_3px_6px_-3px_#bbb] dark:border-[#3b3f5c] dark:!bg-[#3b3f5c] dark:!shadow-none;
}
.noUi-tooltip {
  @apply border-[#D3D3D3] !shadow-none dark:border-[#253b5c] dark:!bg-[#1b2e4b] dark:text-white-dark;
}
.noUi-handle:before,
.noUi-handle:after {
  @apply !h-1.5;
}
